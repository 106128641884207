import {
  calculatePercentageAvailable,
  formatNumber,
  formatDateMonth,
} from '../../../helpers';
type Props = {
  classNameProp: string;
  data: any;
  title: string;
  saldo: string;
  dateIni: string;
  dateEnd: string;
  total: string;
};

const ProgressGraphic = ({
  classNameProp,
  data,
  title,
  saldo,
  dateIni,
  dateEnd,
  total,
}: Props) => {
  return (
    <div
      className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end bg-opacity-0 ${classNameProp}`}
    >
      <div className="card-header pt-5 justify-content-center align-items-center">
        <div className="card-title d-flex flex-column align-items-center">
          <span
            className="fs-2hx fw-bolder lh-1 ls-n2 mb-3"
            style={{ color: 'orange', textAlign: 'center' }}
          >
            {title}
          </span>

          <span className="opacity-75 pt-1 fw-bolder fs-1 text-center">
            {total}
            <span className="fw-normal">
              {total === 'Ilimitado' ? null : saldo}
            </span>
          </span>
        </div>
      </div>
      <div className="card-body d-flex align-items-end pt-0 mt-5">
        <div className="d-flex align-items-center flex-column mt-3 w-100">
          <div className="d-flex justify-content-center fw-bold fs-6 opacity-75 w-100 mt-auto mb-2">
            <span>
              <span className="fw-bolder">
                {calculatePercentageAvailable(data.total, data.consumido)}%
              </span>{' '}
              <span className="fw-normal">Disponible</span>
            </span>
          </div>

          <div
            className="h-8px mx-3 w-100 rounded mb-4 mt-5"
            style={{ backgroundColor: '#D6FFEB' }}
          >
            <div
              className="bg-primary rounded h-10px"
              role="progressbar"
              style={{
                width: `${calculatePercentageAvailable(
                  data.total,
                  data.consumido
                )}%`,
              }}
              aria-valuenow={50}
              aria-valuemin={0}
              aria-valuemax={100}
            ></div>
          </div>
          <div className="row w-100 mb-5 mt-5">
            <div className="col-6 text-start m-0 p-0">
              <div className="mb-1">
                <label className="fw-bolder text-muted">Disponible</label>
                <div className="fw-bolder text-primary">
                  {formatNumber(data.disponibles)} {saldo}
                </div>
              </div>
            </div>
            <div className="col-6 text-end m-0 p-0">
              {' '}
              <div className="mb-1">
                <label className="fw-bolder text-muted">Consumido</label>
                <div className="fw-bold text-muted">
                  {formatNumber(Number(data.consumido))} {saldo}
                </div>
              </div>
            </div>
          </div>
          <div className="w-100 mt-20 pt-5 text-primary fs-7">
            <label className="fw-bolder">Vigencia:</label>{' '}
            {`Del ${formatDateMonth(dateIni)} al ${formatDateMonth(dateEnd)}`}
          </div>
        </div>
      </div>
    </div>
  );
};
export { ProgressGraphic };
