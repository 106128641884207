import React from 'react';

type Props = {
    loading: boolean;
    textButton: string;
    isSubmit: boolean;
    onClick?: () => void;
};

const LoadingButton: React.FC<Props> = ({
    loading,
    textButton,
    isSubmit,
    onClick,
}) => {
    return (
        <button
            className="btn btn-primary"
            disabled={loading}
            onClick={onClick}
            {...(isSubmit ? { type: "submit", ["data-kt-users-modal-action"]: "submit" } : {})}
        >
            {!loading && (
                <span className="indicator-label">{textButton}</span>
            )}
            {loading && (
                <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                >
                    Espere por favor...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
            )}
        </button>
    );
};

export default LoadingButton;
