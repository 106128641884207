import React, { useState, useEffect } from 'react';
import {
  KTSVG,
  LoadingGrid,
  toAbsoluteUrl,
  maskPhoneNumber,
} from '../../../../_mexablock/helpers';
import { TableList } from '../../../../_mexablock/helpers/components/TableList';
import { useGetAll } from '../hooks/CallcenterHook';
import { CreateModal } from '../_modals/CreateModal';
import { MenuComponent } from '../../../../_mexablock/assets/ts/components';

const View: React.FC = () => {
  const [loadingView, setloadingView] = useState(true);
  const [visivilityModal, setVisivilityModal] = useState(false);
  const [titleModal, setTitleModal] = useState('');

  const [reloadGrid, setReloadGrid] = useState(Math.random() * 40);
  const [initFilterValues] = useState({
    nombre: '',
  });
  const { data, loadingRequest } = useGetAll(reloadGrid, initFilterValues);

  useEffect(() => {
    if (loadingRequest > 0) {
      setloadingView(false);
    }
  }, [loadingRequest]);

  setTimeout(() => {
    MenuComponent.reinitialization();
  }, 500);

  const addRegister = () => {
    setVisivilityModal(!visivilityModal);
  };

  const columns: any = [
    {
      id: 'nombre',
      name: <div className="fw-bolder text-muted me-5">Nombre</div>,
      selector: (row: any) =>
        `${row.user.first_name} ${row.user.father_last_name} ${row.user.mother_last_name}`,
      sortable: true,
      width: '30%',
    },
    // {
    //   id: 'correo',
    //   name: <div className="fw-bolder text-muted me-5">Correo</div>,
    //   selector: (row: any) => row.email,
    //   sortable: true,
    //   width: '25%',
    // },
    {
      id: 'telefono',
      name: <div className="fw-bolder text-muted me-5">Teléfono</div>,
      selector: (row: any) => maskPhoneNumber(row.phone),
      sortable: true,
      width: '20%',
    },
    {
      id: 'correo',
      name: <div className="fw-bolder text-muted me-5">Correo</div>,
      selector: (row: any) => row.user.email,
      sortable: true,
      width: '40%',
    },
    {
      id: 'acciones',
      name: <div className="fw-bolder text-muted me-5"></div>,
      cell: (row: any) => dropdownRender(row.id),
      sortable: false,
      width: '10%',
    },
  ];

  const dropdownRender = (id: any) => {
    return (
      <>
        <div className="d-flex justify-content-end flex-shrink-0">
          <a
            href="#/"
            data-kt-menu="true"
            className="btn btn-light btn-active-light-primary btn-sm"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
          >
            Acciones
          </a>

          <div
            className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4"
            data-kt-menu="true"
          >
            <div className="menu-item px-3">
              <a
                className="menu-link px-3"
                href="#/"
                onClick={() => {
                  // onEditRegister(id);
                }}
              >
                Solicitar
              </a>
            </div>
            <div className="menu-item px-3">
              <a
                className="menu-link px-3"
                data-kt-users-table-filter="delete_row"
                href="#/"
                onClick={() => {
                  // onRemoveRegister(id);
                }}
              >
                Enviar
              </a>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="row gy-5 g-xl-8">
        <div className="col-xl-12">
          <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
            <div className="card-header border-0 pt-5">
              {/* <Filter
                initFilterValues={initFilterValues}
                onCleanFilter={onCleanFilter}
                onSearchFilter={onSearchFilter}
              /> */}
              <div className="card-title align-items-start flex-column"></div>
              {/* <div
                className="card-toolbar"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-trigger="hover"
                title="Nuevo registro"
              >
                <a
                  href="#/"
                  className="btn btn-sm btn-light-primary"
                  id="kt_toolbar_primary_button"
                  onClick={addRegister}
                >
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr075.svg"
                    className="svg-icon-5 svg-icon-gray-500 me-1"
                  />
                  Nuevo
                </a>
              </div> */}
            </div>
            {loadingView ? (
              <LoadingGrid text="Cargando..."></LoadingGrid>
            ) : (
              <TableList data={data} columns={columns}></TableList>
            )}
          </div>
        </div>
      </div>
      <CreateModal
        id_row={0}
        title={titleModal}
        show={visivilityModal}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setReloadGrid(Math.random() * 40);
          }
          setVisivilityModal(!visivilityModal);
        }}
      ></CreateModal>
    </>
  );
};

export { View };
