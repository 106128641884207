import { useState, useEffect } from 'react';
import { getConsumption } from '../../../service/consumos/Consumos';
import { SimpleAlert } from '../../../../_mexablock/helpers';
import { ApiResponse, DocData } from '../models/ConsumosModel';

export const useGetAll = (body: any) => {
  const [data, setData] = useState<DocData | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        setIsLoading(true);

        const response = await getConsumption(body);

        if (response.data.error) {
          SimpleAlert(
            response.data.message || 'Error desconocido',
            3000,
            'error'
          );
          return;
        }

        if (response.data.status === 'success') {
          setData(response.data.doc.data);
        }
      } catch (error) {
        SimpleAlert('Error en la solicitud', 3000, 'error');
      }
    };

    if (body && Object.keys(body).length > 0) {
      fetchPost();
    }
  }, [body]);

  return { data, isLoading };
};
