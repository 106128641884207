import React, { useRef } from "react";
import { useLottie } from "lottie-react";
import animationSucces from "../../assets/json/procces-ok.json"

const AnimationSucces =()=> {
    const animationRef = useRef(null)
    const options = {
        animationData: animationSucces,
      };
      const { View, play, stop } = useLottie(options);
      const handleMouseEnter = () => {
        stop();
        play(); 
      };
    return (
        <div onMouseEnter={handleMouseEnter} ref={animationRef}>
        {View}
        </div>
    )

}
export default AnimationSucces;