/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { PageTitle } from '../../../_mexablock/layout/core';
import { ConsumosPage } from './components/View';

const DashboardConsumos: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Consumos</PageTitle>
      <ConsumosPage />
    </>
  );
};

export { DashboardConsumos };
