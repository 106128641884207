import axios from 'axios';
const API_URL = process.env.REACT_APP_API;

export function changeLada({ msisdn, nir }: { msisdn: string; nir: string }) {
  return axios.post(`${API_URL}operation/cambio/lada`, { msisdn, nir });
}

export async function getAll(filters: any) {
  const response = await axios.get(`${API_URL}catalogos/lada-movement`);
  const data = response.data.doc.data;
  return data;
}

export async function getSelectLADAS() {
  const response = await axios.get(
    `${API_URL}catalogos/lada-movement/lada-disponible`
  );
  const data = response.data.doc.data;
  let dataSelect: any = data;
  dataSelect.forEach((row: any) => {
    row.label = row.nir;
    row.value = row.nir;
  });
  return dataSelect;
}
