import axios from 'axios';
const API_URL = process.env.REACT_APP_API;

export function changeSim({
  msisdn,
  newIccid,
}: {
  msisdn: string;
  newIccid: string;
}) {
  return axios.post(`${API_URL}operation/cambio/sim`, { msisdn, newIccid });
}

export async function getAll(filters: any) {
  const response = await axios.get(`${API_URL}catalogos/sim-movement`);
  const data = response.data.doc.data;
  return data;
}
