import axios from 'axios';
const API_URL = process.env.REACT_APP_API;

export function suspend({ msisdn }: { msisdn: string }) {
  return axios.post(`${API_URL}operation/suspension-linea`, {
    msisdn,
  });
}

export async function getAll(filters: any) {
  const response = await axios.get(`${API_URL}catalogos/suspension-linea`);
  const data = response.data.doc.data;
  return data;
}
