import axios from 'axios';
import { maskPhoneNumber, cleanPhoneNumber } from '../../../_mexablock/helpers';
import { EmpresaModel } from '../../pages/empresas/models/EmpresaModel';
const API_URL = process.env.REACT_APP_API;

export async function getSelectEmpresas() {
  const response = await axios.get(`${API_URL}catalogos/empresas`);
  const data = response.data.doc.data;
  let dataSelect: any = data.rows;
  dataSelect.forEach((row: any) => {
    row.label = `${row.name}`;
    row.value = row.id;
  });
  return dataSelect;
}

export async function getAllEmpresas(filters: any) {
  // let search: string = '';
  // if (orderValues) {
  //   if (orderValues.columnName !== '') {
  //     search += `?company.name=${orderValues.sortOrder}`;
  //   }
  // }
  // if (filters) {
  //   if (filters.nombre !== '') {
  //     search += `?nameOpLike=%${filters.nombre}%`;
  //   }
  // }

  const response = await axios.get(`${API_URL}catalogos/empresas?sort=id`);

  // const response = await axios.get(
  //   `${API_URL}catalogos/empresas?sort=id&limit=${startIndex}&page=${endIndex}${search}`
  // );

  const data = response.data.doc.data;
  return data;
}

export async function addEmpresas(values: any) {
  let body = values;
  if (body.user.phone) {
    body.user.phone = body.user.phoneFormat;
    body.type_commission_id = body.account.typeCommission.id;
    body.interbank_key = body.clabe;
  }
  let formData = new FormData();
  formData.append('json', JSON.stringify(body));
  formData.append('pdfFile', body.file);
  const response = await axios({
    method: 'post',
    url: `${API_URL}catalogos/empresas/`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return response;
}
export async function updateEmpresas(values: EmpresaModel) {
  let body = values;
  if (body.user.phone) {
    body.user.phone = body.user.phoneFormat;
  }
  const response = await axios.put(
    `${API_URL}catalogos/empresas/company/${body.id}/user/${body.user.id}`,
    body
  );
  return response;
}
// export async function getOneEmpresas(id_row: number) {
//   const response = await axios.get(`${API_URL}catalogos/empresas/${id_row}`);
//   const { company, id, alias, first_name, father_last_name, mother_last_name, email, phone, curp, rfc, birthdate, active, gender } = response.data.doc.data;
//   return {
//     id: company.id || 0,
//     name: company.name || '',
//     business_name: company.business_name || '',
//     type_id: company.type_id || 0,
//     manager: {
//       id: id || 0,
//       alias: alias || '',
//       first_name: first_name || '',
//       father_last_name: father_last_name || '',
//       mother_last_name: mother_last_name || '',
//       email: email || '',
//       phone: maskPhoneNumber(phone) || '',
//       phoneFormat: cleanPhoneNumber(phone) || '',
//       curp: curp || '',
//       rfc: rfc || '',
//       birthdate: birthdate || new Date(),
//       active: active || false,
//       gender: {
//         id: gender.id || 0,
//         code: gender.code || '',
//         name: gender.name || '',
//       }
//     }
// };
// }
export async function getOneEmpresas(id_row: number) {
  const response = await axios.get(`${API_URL}catalogos/empresas/${id_row}`);
  let responseData = response.data.doc.data;
  responseData.user.phone = maskPhoneNumber(responseData.user.phone);
  responseData.user.phoneFormat = cleanPhoneNumber(responseData.user.phone);
  if (responseData.type.code === 'SOC') {
    responseData.name = '';
  }
  return responseData;
}

export async function removeEmpresas(id: number) {
  const response = await axios.delete(`${API_URL}catalogos/empresas/${id}`);
  return response;
}

export async function getEmpresaById(id_row: number) {
  const response = await axios.get(`${API_URL}catalogos/empresas/${id_row}`);
  let responseData = response.data.doc.data;

  return responseData;
}
