import React from 'react';
import "./RadioButtons.css";
import { FormikProps } from 'formik';
import Tooltip from '../Tooltip';

type Props = {
    name: string
    formData: FormikProps<any>;
    title?: string;
    options: {
        id: string | number;
        value: any;
        label: string;
    }[];
    onChange?: (value: any) => void;
};

const RadioButtons: React.FC<Props> = ({
    name,
    formData,
    title,
    options,
    onChange,
}) => {
    return (
        <Tooltip
            show={Boolean(formData.touched[name] && formData.errors[name])}
            content={formData.errors[name]}
            type='error'
            side='top'
        >
            <fieldset className="row g-9 mb-13 justify-content-center">
                <h3 className="row g-9 justify-content-center">
                    {title}
                </h3>
                {options.map((option) => (
                    <div key={option.id} className="col-md-2 d-flex align-items-center justify-content-center">
                        <div className="d-flex flex-row align-items-center">
                            <input
                                className="form-check-input mb-3 mb-lg-0 radio-button"
                                type="radio"
                                value={option.value}
                                id={String(option.id)}
                                name={name}
                                checked={formData.values[name] === option.value}
                                onChange={() => {
                                    formData.setFieldValue(name, option.value);
                                    if (onChange) onChange(option.value);
                                }}
                            />
                            <label htmlFor={String(option.id)} className="fw-bold fs-6 mb-2 ms-2 w-auto">{option.label}</label>
                        </div>
                    </div>
                ))}
            </fieldset>
        </Tooltip>
    );
};

export default RadioButtons;
