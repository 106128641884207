/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, CSSProperties, useEffect } from 'react';
import { KTSVG, countDaysBetweenDates } from '../../../../_mexablock/helpers';
import { Collapse } from 'react-bootstrap-v5';
import { ProgressGraphic } from '../../../../_mexablock/partials/widgets/graphics/progress';
import { RadialGraphic } from '../../../../_mexablock/partials/widgets/graphics/radial';

type Props = {
  dataView: any;
  collapse: boolean;
};

const CollapseGraphic: React.FC<Props> = ({ dataView, collapse }) => {
  const [openSectionInfoGeneral, setOpenSectionInfoGeneral] =
    useState<boolean>(collapse);
  const [data, setData] = useState(dataView);

  useEffect(() => {
    setData(dataView);
  }, [dataView]);

  const containerStyle: CSSProperties = {
    width: '100%',
    textAlign: 'center',
    borderRadius: '5px',
    backgroundColor: '#f8f9f9',
  };

  const columnStyle: CSSProperties = {
    flex: 1,
    padding: '3px',
    borderRight: '2px solid #eaeded',
  };

  const lastColumnStyle: CSSProperties = {
    ...columnStyle,
    borderRight: 'none',
  };

  return (
    <>
      <div className="row gy-5 g-xl-8">
        <div className="col-xl-12 mb-xl-12">
          <div className="card border">
            <div
              className="card-header border-0 cursor-pointer d-flex justify-content-between align-items-center"
              onClick={() => setOpenSectionInfoGeneral(!openSectionInfoGeneral)}
              aria-controls="collapseExample"
            >
              <div className="d-flex align-items-center">
                {/* <KTSVG
                  path="/media/icons/duotune/general/gen008.svg"
                  className="svg-icon-3x d-block my-2"
                /> */}
                <h3 className="fw-bolder m-0 ms-3 me-3 text-primary">
                  Paquete {data.offerName}
                </h3>
                <p className="p-0 m-0 fw-bold" style={{ color: '#f39c12' }}>
                  {countDaysBetweenDates(
                    data.minutos.nacional.starts,
                    data.minutos.nacional.ends
                  ) <= 7
                    ? 'SEMANAL'
                    : 'MENSUAL'}{' '}
                  (
                  {countDaysBetweenDates(
                    data.minutos.nacional.starts,
                    data.minutos.nacional.ends
                  )}{' '}
                  días de vigencia)
                </p>
              </div>
              <div className="justify-content-center align-items-center">
                <div
                  className={`align-self-center`}
                  style={{
                    transform: openSectionInfoGeneral
                      ? 'translateX(10%) rotate(-180deg)'
                      : '',
                    transition: 'transform 0.3s ease',
                  }}
                >
                  <KTSVG
                    path={'/media/icons/duotune/arrows/arr072.svg'}
                    className={`svg-icon-1`}
                  />
                </div>
              </div>
            </div>
            <Collapse in={openSectionInfoGeneral}>
              <div id="collapseExample">
                <div className="card-body border-top">
                  {/* Aqui van las graficas */}
                  <div
                    className="row p-0 m-0 d-flex align-items-center"
                    style={containerStyle}
                  >
                    <div className="col-4" style={columnStyle}>
                      <ProgressGraphic
                        classNameProp="h-md-50 mb-5 mb-xl-10"
                        data={data.minutos}
                        title="Voz"
                        saldo="Minutos"
                        dateIni={data.minutos.nacional.starts}
                        dateEnd={data.minutos.nacional.ends}
                        total={
                          data.minutos.total >= 5000
                            ? 'Ilimitado'
                            : String(data.minutos.total)
                        }
                      />
                    </div>
                    <div className="col-8">
                      <div className="row">
                        <div className="col-6" style={columnStyle}>
                          <RadialGraphic
                            chartHeight=""
                            className=""
                            chartColor="#D6FFEB"
                            title="Territorio Nacional"
                            total={data.minutos.nacional.total}
                            consumo={data.minutos.nacional.consumido}
                            diponible={data.minutos.nacional.disponibles}
                            saldo="Minutos"
                            adquiridoAlta={data.minutos.nacional.total}
                            consumidoAlta={data.minutos.nacional.consumido}
                            disponibleAlta={data.minutos.nacional.disponibles}
                          ></RadialGraphic>
                        </div>
                        <div className="col-6" style={lastColumnStyle}>
                          <RadialGraphic
                            chartHeight=""
                            className=""
                            chartColor="#D6FFEB"
                            title="Territorio Internacional"
                            total={data.minutos.internacional.total}
                            consumo={data.minutos.internacional.consumido}
                            diponible={data.minutos.internacional.disponibles}
                            saldo="Minutos"
                            adquiridoAlta={data.minutos.internacional.total}
                            consumidoAlta={data.minutos.internacional.consumido}
                            disponibleAlta={
                              data.minutos.internacional.disponibles
                            }
                          ></RadialGraphic>
                        </div>
                      </div>{' '}
                    </div>
                  </div>
                </div>

                <div className="card-body">
                  {/* Aqui van las graficas */}
                  <div
                    className="row p-0 m-0 d-flex align-items-center"
                    style={containerStyle}
                  >
                    <div className="col-4" style={columnStyle}>
                      <ProgressGraphic
                        classNameProp="h-md-50 mb-5 mb-xl-10"
                        data={data.internet}
                        title="Datos"
                        saldo="MB"
                        dateIni={data.internet.nacional.rapidos.starts}
                        dateEnd={data.internet.nacional.rapidos.ends}
                        total={String(data.internet.total)}
                      />
                    </div>
                    <div className="col-8">
                      <div className="row">
                        <div className="col-6" style={columnStyle}>
                          <RadialGraphic
                            chartHeight=""
                            className=""
                            chartColor="#D6FFEB"
                            title="Territorio Nacional"
                            low={data.internet.nacional.lentos}
                            total={data.internet.nacional.total}
                            consumo={data.internet.nacional.consumido}
                            diponible={data.internet.nacional.disponibles}
                            saldo="MB"
                            adquiridoAlta={data.internet.nacional.rapidos.total}
                            adquiridoBaja={data.internet.nacional.lentos.total}
                            consumidoAlta={
                              data.internet.nacional.rapidos.consumido
                            }
                            consumidoBaja={
                              data.internet.nacional.lentos.consumido
                            }
                            disponibleAlta={
                              data.internet.nacional.rapidos.disponibles
                            }
                            disponibleBaja={
                              data.internet.nacional.lentos.disponibles
                            }
                          ></RadialGraphic>
                        </div>
                        <div className="col-6" style={lastColumnStyle}>
                          <RadialGraphic
                            chartHeight=""
                            className=""
                            chartColor="#D6FFEB"
                            title="Territorio Internacional"
                            low={data.internet.internacional.lentos}
                            saldo="MB"
                            total={data.internet.internacional.total}
                            consumo={data.internet.internacional.consumido}
                            diponible={data.internet.internacional.disponibles}
                            adquiridoAlta={
                              data.internet.internacional.rapidos.total
                            }
                            adquiridoBaja={
                              data.internet.internacional.lentos.total
                            }
                            consumidoAlta={
                              data.internet.internacional.rapidos.consumido
                            }
                            consumidoBaja={
                              data.internet.internacional.lentos.consumido
                            }
                            disponibleAlta={
                              data.internet.internacional.rapidos.disponibles
                            }
                            disponibleBaja={
                              data.internet.internacional.lentos.disponibles
                            }
                          ></RadialGraphic>
                        </div>
                      </div>{' '}
                    </div>
                  </div>
                </div>

                <div className="card-body">
                  {/* Aqui van las graficas */}
                  <div
                    className="row p-0 m-0 d-flex align-items-center"
                    style={containerStyle}
                  >
                    <div className="col-4" style={columnStyle}>
                      <ProgressGraphic
                        classNameProp="h-md-50 mb-5 mb-xl-10"
                        data={data.mensajes}
                        title="SMS"
                        saldo="Mensajes"
                        dateIni={data.mensajes.nacional.starts}
                        dateEnd={data.mensajes.nacional.ends}
                        total={
                          data.mensajes.total >= 1500
                            ? 'Ilimitado'
                            : String(data.mensajes.total)
                        }
                      />
                    </div>
                    <div className="col-8">
                      <div className="row">
                        <div className="col-6" style={columnStyle}>
                          <RadialGraphic
                            chartHeight=""
                            className=""
                            chartColor="#D6FFEB"
                            title="Territorio Nacional"
                            saldo="Mensajes"
                            total={data.mensajes.nacional.total}
                            consumo={data.mensajes.nacional.consumido}
                            diponible={data.mensajes.nacional.disponibles}
                            adquiridoAlta={data.mensajes.nacional.total}
                            consumidoAlta={data.mensajes.nacional.consumido}
                            disponibleAlta={data.mensajes.nacional.disponibles}
                          ></RadialGraphic>
                        </div>
                        <div className="col-6" style={lastColumnStyle}>
                          <RadialGraphic
                            chartHeight=""
                            className=""
                            chartColor="#D6FFEB"
                            title="Territorio Internacional"
                            total={data.mensajes.internacional.total}
                            consumo={data.mensajes.internacional.consumido}
                            diponible={data.mensajes.internacional.disponibles}
                            saldo="Mensajes"
                            adquiridoAlta={data.mensajes.internacional.total}
                            consumidoAlta={
                              data.mensajes.internacional.consumido
                            }
                            disponibleAlta={
                              data.mensajes.internacional.disponibles
                            }
                          ></RadialGraphic>
                        </div>
                      </div>{' '}
                    </div>
                  </div>
                </div>
                {data.bonos ? (
                  <div className="card-body">
                    <div
                      className="row p-0 m-0 d-flex align-items-center"
                      style={containerStyle}
                    >
                      <div className="col-4" style={columnStyle}>
                        <ProgressGraphic
                          classNameProp="h-md-50 mb-5 mb-xl-10"
                          data={data.bonos}
                          title="Bono"
                          saldo="MB"
                          dateIni={data.bonos.nacional.rapidos.starts}
                          dateEnd={data.bonos.nacional.rapidos.ends}
                          total={String(data.bonos.total)}
                        />
                      </div>
                      <div className="col-8">
                        <div className="row">
                          <div className="col-6" style={columnStyle}>
                            <RadialGraphic
                              chartHeight=""
                              className=""
                              chartColor="#D6FFEB"
                              title="Territorio Nacional"
                              low={data.bonos.nacional.lentos}
                              saldo="MB"
                              total={data.bonos.nacional.total}
                              consumo={data.bonos.nacional.consumido}
                              diponible={data.bonos.nacional.disponibles}
                              adquiridoAlta={data.bonos.nacional.rapidos.total}
                              consumidoAlta={
                                data.bonos.nacional.rapidos.consumido
                              }
                              disponibleAlta={
                                data.bonos.nacional.rapidos.disponibles
                              }
                              disponibleBaja={
                                data.bonos.nacional.lentos.disponibles
                              }
                              consumidoBaja={
                                data.bonos.nacional.lentos.consumido
                              }
                              adquiridoBaja={data.bonos.nacional.lentos.total}
                            ></RadialGraphic>
                          </div>
                          <div className="col-6" style={columnStyle}>
                            <RadialGraphic
                              chartHeight=""
                              className=""
                              chartColor="#D6FFEB"
                              title="Territorio Internacional"
                              low={data.bonos.internacional.lentos}
                              saldo="MB"
                              total={data.bonos.internacional.total}
                              consumo={data.bonos.internacional.consumido}
                              diponible={data.bonos.internacional.disponibles}
                              adquiridoAlta={
                                data.bonos.internacional.rapidos.total
                              }
                              consumidoAlta={
                                data.bonos.internacional.rapidos.consumido
                              }
                              disponibleAlta={
                                data.bonos.internacional.rapidos.disponibles
                              }
                              disponibleBaja={
                                data.bonos.internacional.lentos.disponibles
                              }
                              consumidoBaja={
                                data.bonos.internacional.lentos.consumido
                              }
                              adquiridoBaja={
                                data.bonos.internacional.lentos.total
                              }
                            ></RadialGraphic>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {data.redes ? (
                  <div className="card-body">
                    <div
                      className="row p-0 m-0 d-flex align-items-center"
                      style={containerStyle}
                    >
                      <div className="col-4" style={columnStyle}>
                        <ProgressGraphic
                          classNameProp="h-md-50 mb-5 mb-xl-10"
                          data={data.redes}
                          title="Redes"
                          saldo="MB"
                          dateIni={data.redes.nacional.rapidos.starts}
                          dateEnd={data.redes.nacional.rapidos.ends}
                          total={
                            data.redes.total >= 40000
                              ? 'Ilimitado'
                              : String(data.redes.total)
                          }
                        />
                      </div>
                      <div className="col-8">
                        <div className="row">
                          <div className="col-6" style={columnStyle}>
                            <RadialGraphic
                              chartHeight=""
                              className=""
                              chartColor="#D6FFEB"
                              title="Territorio Nacional"
                              low={data.redes.nacional.lentos}
                              saldo="MB"
                              total={data.redes.nacional.total}
                              consumo={data.redes.nacional.consumido}
                              diponible={data.redes.nacional.disponibles}
                              adquiridoAlta={data.redes.nacional.rapidos.total}
                              adquiridoBaja={data.redes.nacional.lentos.total}
                              consumidoAlta={
                                data.redes.nacional.rapidos.consumido
                              }
                              consumidoBaja={
                                data.redes.nacional.lentos.consumido
                              }
                              disponibleAlta={
                                data.redes.nacional.rapidos.disponibles
                              }
                              disponibleBaja={
                                data.redes.nacional.lentos.disponibles
                              }
                            ></RadialGraphic>
                          </div>
                          <div className="col-6" style={columnStyle}>
                            <RadialGraphic
                              chartHeight=""
                              className=""
                              chartColor="#D6FFEB"
                              title="Territorio Internacional"
                              low={data.redes.internacional.lentos}
                              saldo="MB"
                              total={data.redes.internacional.total}
                              consumo={data.redes.internacional.consumido}
                              diponible={data.redes.internacional.disponibles}
                              adquiridoAlta={
                                data.redes.internacional.rapidos.total
                              }
                              adquiridoBaja={
                                data.redes.internacional.lentos.total
                              }
                              consumidoAlta={
                                data.redes.internacional.rapidos.consumido
                              }
                              consumidoBaja={
                                data.redes.internacional.lentos.consumido
                              }
                              disponibleAlta={
                                data.redes.internacional.rapidos.disponibles
                              }
                              disponibleBaja={
                                data.redes.internacional.lentos.disponibles
                              }
                            ></RadialGraphic>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </Collapse>
          </div>
        </div>
      </div>
    </>
  );
};

export { CollapseGraphic };
