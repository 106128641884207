export const styleSelect = {
    placeholder: (provided: any, state: any) => ({
      ...provided,
      color: '#181C32',
    }),
    indicatorSeparator: (provided: any, state: any) => ({
      ...provided,
      display: 'none',
    }),
    dropdownIndicator: (provided: any, state: any) => ({
      ...provided,
      color: '#9096EC',
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      border: '1px solid #E4E6EF',
      borderRadius: '6px',
      padding: '3px',
      fontSize: '1rem',
      boxShadow: 'none',
      backgroundColor: state.isDisabled ? '#EFF2F5' : 'white',
    }),
    singleValue: (provided: any, state: any) => ({
      ...provided,
      color: state.isDisabled ? '#181C32' : 'inherit',
      fontWeight: 500,
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      fontSize: state.data.value === 'new' ? '1.1rem' : '1.0rem',
      color: state.data.value === 'new' ? '#9096EC' : '#000',
    }),
    menubar: (provided: any, state: any) => ({
      ...provided,
      zIndex: 999,
    }),
  };