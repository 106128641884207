import React, { useRef } from 'react';
import './Tooltip.css';

type Props = {
    show?: boolean;
    hoverable?: boolean;
    side?: 'top' | 'bottom' | 'right' | 'left' | 'top-right' | 'top-left';
    type?: 'default' | 'error' | 'information';
    content?: React.ReactNode;
    className?: string
};

const Tooltip: React.FC<React.PropsWithChildren<Props>> = ({
    children,
    content,
    hoverable = false,
    show = true,
    side = 'top',
    type = 'default',
}) => {
    return (
        <div className='tooltip-parent'>
            <div className={`tooltip-content tooltip-${type} tooltip-${side} ${show ? 'd-block' : 'd-none'} ${hoverable ? 'tooltip-hover' : 'tooltip-no-hover'}`}>
                {content}
            </div>
            <div>
                {children}
            </div>
        </div>
    );
};

export default Tooltip;
