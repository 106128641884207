import React, { useState, useEffect, useRef } from 'react';
import { StepperComponent } from '../../assets/ts/components';
import LoadingButton from './LoadingButton';

type Props = {
  children?: React.ReactNode;
  headers: string[];
  stepper: React.MutableRefObject<StepperComponent | null>;
  textButton: string;
  loading: boolean;
  onCancel: () => void;
  onClick?: () => void;
  onBack?: (actionBack: any) => void;
};

const Stepper: React.FC<Props> = ({
  children,
  headers,
  stepper,
  textButton,
  loading,
  onCancel,
  onClick,
  onBack,
}) => {
  const stepperRef = useRef<HTMLDivElement | null>(null);
  const [currentStep, setCurrentStep] = useState(0);
  useEffect(() => {
    if (stepperRef.current) {
      stepper.current = StepperComponent.createInsance(
        stepperRef.current as HTMLDivElement
      );
      if (stepper.current) {
        stepper.current.on('kt.stepper.changed', () => {
          const stepIndex = stepper.current?.getCurrentStepIndex();
          setCurrentStep(stepIndex || 0);
        });
      }
    }
  }, [stepperRef]);

  const handlePrevious = () => {
    if (stepper.current) {
      stepper.current.goPrev();
      const prevStepIndex = stepper.current.getCurrentStepIndex() - 1;
      setCurrentStep(prevStepIndex);
      if (onBack) {
        onBack(prevStepIndex);
      }
    }
  };

  const isLastStep = currentStep === headers.length;

  return (
    <div
      ref={stepperRef}
      className="stepper stepper-links d-flex flex-column gap-5 first"
      id="kt_modal_create_app_stepper"
    >
      <div className="stepper-nav justify-content-center py-2">
        {headers.map((header, index) => (
          <div
            className={
              index === 0 ? 'stepper-item current' : 'stepper-item pending'
            }
            data-kt-stepper-element="nav"
            key={index}
          >
            <h3 className="stepper-title">{header}</h3>
          </div>
        ))}
      </div>
      <div className="w-100">
        {React.Children.map(children, (child, index) => (
          <div
            className={
              index === 0 ? 'current min-h-500px' : 'pending min-h-500px'
            }
            data-kt-stepper-element="content"
            key={index}
          >
            {child}
          </div>
        ))}
      </div>
      <div className="text-center">
        {currentStep > 0 && !isLastStep && (
          <button
            type="button"
            className="btn btn-light me-3"
            data-kt-users-modal-action="previous"
            onClick={handlePrevious}
          >
            Regresar
          </button>
        )}

        {/* <LoadingButton
          isSubmit={!Boolean(onClick)}
          loading={loading}
          textButton={textButton}
          {...(onClick ? { onClick } : {})}
        /> */}

        {!isLastStep ? (
          <LoadingButton
            isSubmit={!Boolean(onClick)}
            loading={loading}
            textButton={textButton}
            {...(onClick ? { onClick } : {})}
          />
        ) : (
          <button type="button" className="btn btn-primary" onClick={onCancel}>
            Cerrar
          </button>
        )}
      </div>
    </div>
  );
};

export default Stepper;
