import axios from 'axios'
const API_URL = process.env.REACT_APP_API;

export async function getAllCommissions(filters: any) {
    const response = await axios.get(
        `${API_URL}catalogos/commissions/`,
        { params: filters },
    );
    const data = response.data.doc.data;
    return data;
}
