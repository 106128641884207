import React, { FC } from 'react';
import { PageTitle, PageLink } from '../../../_mexablock/layout/core';
import { View } from './components/View';

const breadCrumbs: Array<PageLink> = [];

const ReactivacionPage: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={breadCrumbs}>Reactivación de linea</PageTitle>
      <View />
    </>
  );
};

export { ReactivacionPage };
