import React, { useState, useEffect } from 'react';
import { Usuarios } from './Usuarios';
import { useParams } from 'react-router-dom';
import { MenuComponent } from '../../../../../_mexablock/assets/ts/components';
import axios from 'axios';
import * as Yup from 'yup';
import { useFormik } from 'formik';
const API_URL = process.env.REACT_APP_API;

const View: React.FC = () => {
  const { id }: any = useParams();
  const [data, setData] = useState<any>();
  const [name, setName] = useState('');
  const [business, setBusiness] = useState('');
  const [email, setEmail] = useState('');
  const [rfc, setRfc] = useState('');
  const [clabe, setClabe] = useState('');
  const [stripe, setStripe] = useState('');
  const [cfdiSeleccionado, setCfdiSeleccionado] = useState('');
  const [catalogoCFDI, setCatalogoCFDI] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [usuarios, setUsuarios] = useState<any>([]);
  const [tabs, setTabs] = useState<string | 'USERS'>('USERS');

  const schemaBalance = Yup.object().shape({
    name: Yup.string().min(1, ' Requerido.').required('Requerido.'),
    business_name: Yup.string().min(1, ' Requerido.').required('Requerido.'),
    email: Yup.string()
      .email('El formato de email es incorrecto')
      .min(1, ' Requerido.')
      .required('Requerido.'),
    rfc: Yup.string().min(1, ' Requerido.').required('Requerido.'),
    interbank_key: Yup.string().min(1, ' Requerido.').required('Requerido.'),
    stripe_id: Yup.string().min(1, ' Requerido.').required('Requerido.'),
    cfdi: Yup.number().min(1, ' Requerido.').required('Requerido.'),
  });

  const formik = useFormik({
    initialValues: {
      name: name,
      business_name: business,
      email: email,
      rfc: rfc,
      interbank_key: clabe,
      stripe_id: stripe,
      cfdi: cfdiSeleccionado,
    },
    validationSchema: schemaBalance,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting, setFieldError }) => {
      setLoading(true);
      setTimeout(() => {
        axios
          .patch(`${API_URL}catalogos/empresas/${id} `, values)

          .then(() => {
            getEmpresaById(id);
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
          });
      }, 1000);
    },
  });

  const obtieneCDFI = () => {
    axios(`${API_URL}catalogs/CFDI`)
      .then((response) => {
        setCatalogoCFDI(response.data.doc.data.rows);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getEmpresaById = (ident: string) => {
    axios(`${API_URL}catalogos/empresas/ ${ident}`)
      .then((response) => {
        setData(response.data.doc.data);
        setName(response.data.doc.data.name);
        setBusiness(response.data.doc.data.business_name);
        setEmail(response.data.doc.data.email);
        setRfc(response.data.doc.data.rfc);
        setClabe(response.data.doc.data.interbank_key);
        setStripe(response.data.doc.data.stripe_id);
        setCfdiSeleccionado(response.data.doc.data.cfdi_id);
        setUsuarios(response.data.doc.data.user);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getEmpresaById(id);
    obtieneCDFI();
  }, []);

  MenuComponent.reinitialization();

  return !data ? (
    <></>
  ) : (
    <>
      <div className="row gy-5 g-xl-8">
        <div className="col-xl-12">
          <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
            <div className="card-body py-3 mt-5">
              <div className="mb-17">
                <div className="d-flex flex-stack mb-5">
                  <h3 className="text-gray-900">Socios</h3>
                </div>

                <div className="modal-body scroll-y mx-5 pt-0">
                  <div className="text-center mb-13">
                    <div className="text-muted fw-semibold fs-5">
                      Actualizar información de socio / empresa.
                    </div>
                  </div>

                  <form onSubmit={formik.handleSubmit}>
                    <div className="row g-9 mb-5">
                      <div className="col-md-3 fv-row fv-plugins-icon-container">
                        <div className="w-100 d-flex">
                          <label className=" fw-bold fs-6 mb-2 w-auto required">
                            Tipo
                          </label>
                        </div>
                        <input
                          placeholder="Empresa / Socio"
                          className="form-control"
                          type="text"
                          name="name"
                          autoComplete="off"
                          disabled
                          value={data.type.name}
                        />
                      </div>

                      <div className="col-md-3 fv-row fv-plugins-icon-container">
                        <div className="w-100 d-flex">
                          <label className=" fw-bold fs-6 mb-2 w-auto required">
                            Nombre
                          </label>
                          {formik.touched.name && formik.errors.name && (
                            <div className="fv-plugins-message-container text-danger w-100 ms-5">
                              <span role="alert">{formik.errors.name}</span>
                            </div>
                          )}
                        </div>
                        <input
                          placeholder="Nombre"
                          className="form-control"
                          type="text"
                          name="name"
                          autoComplete="off"
                          onChange={(event) => setName(event.target.value)}
                          value={formik.values.name}
                        />
                      </div>

                      <div className="col-md-3">
                        <div className="w-100 d-flex">
                          <label className="required fw-bold fs-6 mb-2 w-auto">
                            Razón social
                          </label>
                          {formik.touched.business_name &&
                            formik.errors.business_name && (
                              <div className="fv-plugins-message-container text-danger w-auto ms-5">
                                <span role="alert">
                                  {formik.errors.business_name}
                                </span>
                              </div>
                            )}
                        </div>
                        <input
                          placeholder="Razón social"
                          className="form-control"
                          type="text"
                          name="business_name"
                          autoComplete="off"
                          onChange={(event) => setBusiness(event.target.value)}
                          value={formik.values.business_name}
                        />
                      </div>
                      <div className="col-md-3">
                        <div className="w-100 d-flex">
                          <label className="required fw-bold fs-6 mb-2 w-auto">
                            Comisión
                          </label>
                        </div>
                        <input
                          placeholder="Tipo Comisión"
                          className="form-control"
                          type="text"
                          name="name"
                          autoComplete="off"
                          disabled
                          value={data.account.typeCommission.name}
                        />
                      </div>

                      <div className="row g-9 mb-1">
                        <div className="col-md-4 fv-row fv-plugins-icon-container">
                          <div className="w-100 d-flex">
                            <label className="required fw-bold fs-6 mb-2 w-auto">
                              Uso de CFDI
                            </label>
                            {/* {formik.touched.cfdi?.id && formik.errors.cfdi?.id && (
                    <div className="fv-plugins-message-container text-danger w-100 ms-5">
                      <span role="alert">{formik.errors.cfdi.id}</span>
                    </div>
                  )} */}
                          </div>
                          <select
                            className="form-select"
                            data-kt-select2="true"
                            data-placeholder="Select option"
                            data-allow-clear="true"
                            onChange={(event) =>
                              setCfdiSeleccionado(event.target.value)
                            }
                          >
                            <option value={cfdiSeleccionado}>Seleccione</option>
                            {catalogoCFDI.map((item: any, index: number) => (
                              <option key={index} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-md-4 fv-row fv-plugins-icon-container">
                          <div className="w-100 d-flex">
                            <label className="required fw-bold fs-6 mb-2 w-40">
                              Correo
                            </label>
                            {formik.touched.email && formik.errors.email && (
                              <div className="fv-plugins-message-container text-danger w-60 ms-5">
                                <span role="alert">{formik.errors.email}</span>
                              </div>
                            )}
                          </div>
                          <input
                            placeholder="Correo"
                            className="form-control mb-3 mb-lg-0"
                            type="text"
                            name="email"
                            autoComplete="off"
                            onChange={(event) => setEmail(event.target.value)}
                            value={formik.values.email}
                          />
                        </div>
                        <div className="col-md-4 fv-row fv-plugins-icon-container">
                          <div className="w-100 d-flex">
                            <label className=" fw-bold fs-6 mb-2 w-auto required">
                              RFC
                            </label>
                            {formik.touched.rfc && formik.errors.rfc && (
                              <div className="fv-plugins-message-container text-danger w-auto ms-5">
                                <span role="alert">{formik.errors.rfc}</span>
                              </div>
                            )}
                          </div>
                          <input
                            placeholder="RFC"
                            className="form-control mb-3 mb-lg-0"
                            maxLength={13}
                            type="text"
                            name="rfc"
                            autoComplete="off"
                            onChange={(event) => setRfc(event.target.value)}
                            value={formik.values.rfc}
                          />
                        </div>
                      </div>
                      <div className="row g-9 mb-5">
                        <div className="col-md-6 fv-row fv-plugins-icon-container">
                          <div className="w-100 d-flex">
                            <label className="required fw-bold fs-6 mb-2 w-40">
                              CLABE Interbancaria
                            </label>
                            {formik.touched.interbank_key &&
                              formik.errors.interbank_key && (
                                <div className="fv-plugins-message-container text-danger w-60 ms-5">
                                  <span role="alert">
                                    {formik.errors.interbank_key}
                                  </span>
                                </div>
                              )}
                          </div>
                          <input
                            placeholder="CLABE"
                            className="form-control mb-3 mb-lg-0"
                            maxLength={18}
                            type="text"
                            name="interbank_key"
                            autoComplete="off"
                            onChange={(event) => setClabe(event.target.value)}
                            value={formik.values.interbank_key}
                          />
                        </div>
                        <div className="col-md-6 fv-row fv-plugins-icon-container">
                          <div className="w-100 d-flex">
                            <label className="required fw-bold fs-6 mb-2 w-40">
                              ID STRIPE
                            </label>
                            {formik.touched.stripe_id &&
                              formik.errors.stripe_id && (
                                <div className="fv-plugins-message-container text-danger w-60 ms-5">
                                  <span role="alert">
                                    {formik.errors.stripe_id}
                                  </span>
                                </div>
                              )}
                          </div>
                          <input
                            placeholder="ID Stripe"
                            className="form-control"
                            type="text"
                            maxLength={21}
                            name="stripe_id"
                            autoComplete="off"
                            onChange={(event) => setStripe(event.target.value)}
                            value={formik.values.stripe_id}
                          />
                        </div>
                      </div>

                      <div className="text-center pt-10">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          data-kt-users-modal-action="submit"
                          disabled={loading}
                        >
                          {!loading && (
                            <span className="indicator-label">Actualizar</span>
                          )}
                          {loading && (
                            <span
                              className="indicator-progress"
                              style={{ display: 'block' }}
                            >
                              Espere por favor...
                              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="row mb-5">
                  <div className="col-xxl-8 col-xl-8 col-md-8 col-lg-8">
                    <ul className="nav nav-line-tabs nav-line-tabs-2x border-transparent fs-7 fw-bolder flex-nowrap">
                      <li className="nav-item cursor-pointer ">
                        <a
                          className={`nav-link me-6 ${
                            tabs === 'USERS' ? 'text-active-primary active' : ''
                          } `}
                          onClick={() => {
                            setTabs('USERS');
                          }}
                        >
                          <span className="margin-left-05">Usuarios</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div>
                  {tabs === 'USERS' ? <Usuarios datos={usuarios} /> : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { View };
