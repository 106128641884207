import axios from 'axios';
const API_URL = process.env.REACT_APP_API;

export async function getCatalog(code: string) {
  const response = await axios.get(`${API_URL}catalogs/${code}`);
  const data = response.data.doc.data;
  return data;
}
export async function getSelectCatalog(code: string) {
  const response = await axios.get(`${API_URL}catalogs/${code}`);
  return response.data.doc.data.rows.map((item: any) => ({
    ...item,
    label: item.name,
    value: item.id,
  }));
}
export async function getCatalogGrid(
  code: string,
  startIndex: number,
  endIndex: number,
  filters: any
) {
  let search: string = '';
  if (filters) {
    search = `&nameOpLike=%${filters}%`;
  }
  const response = await axios.get(
    `${API_URL}catalogs/${code}?limit=${startIndex}&page=${endIndex}${search}`
  );
  const data = response.data.doc.data;
  return data;
}
export async function addValueCatalog(code_catalogo: string, body: any) {
  const { code, name, order } = body;
  const data = {
    code: code,
    name: name,
    order: order,
  };
  const response = await axios.post(
    `${API_URL}catalogs/${code_catalogo}`,
    data
  );
  return response;
}
export async function removeRegisterCatalog(id: number) {
  const response = await axios.delete(`${API_URL}catalogs/${id}`);
  return response;
}

export async function validatePhone({
  msisdn,
  iccid,
}: {
  msisdn: string | null;
  iccid: string | null;
}) {
  return axios.post(`${API_URL}catalogos/customer/validate`, {
    [msisdn ? 'msisdn' : 'iccid']: msisdn ? msisdn : iccid,
  });
}
