import React, { Suspense, lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { FallbackView } from '../../_mexablock/partials';
import { userHasRole } from '../../_mexablock/helpers';
import { UserModel } from '../modules/auth/models/UserModel';
import { RootState } from '../../setup';
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper';
import { ErrorsPage } from '../modules/errors/ErrorsPage';
import { UsuariosPage } from '../pages/usuarios/Usuarios';
import { EmpresasPage } from '../pages/empresas/Empresas';
import { SocioPage } from '../pages/empresas/socios';
import { DistribuidorPage } from '../pages/distribuidor/Distribuidor';
import { ClientesPage } from '../pages/clientes/Clientes';
import { EmpleadosPage } from '../pages/empleados/Empleados';
import { CuentasPage } from '../pages/operacion/cuentas/Cuentas';
import { ActivacionPage } from '../pages/operacion/activacion/Activacion';
import { ValidacionPage } from '../pages/operacion/validacion/Validacion';
import { RecargaPage } from '../pages/operacion/recarga/Recarga';
import { PortabilityPage } from '../pages/operacion/portabilidad/Portability';
import { CambioMSISDNPage } from '../pages/operacion/cambio_msisdn/CambioMSISDN';
import { ReporteActivacionesPage } from '../pages/reportes/activaciones';
import { ReporteRecargasPage } from '../pages/reportes/recargas';
import { ReporteEstadoCuentaPage } from '../pages/reportes/estado_cuenta';
import { ReporteComisionesPage } from '../pages/reportes/comisiones';
import { ConfigEquiposPage } from '../pages/ayuda/config_equipos';
import { CallCenterPage } from '../pages/callCenter/CallCenter';
import { NotificationsPage } from '../pages/notificaciones/Notificaciones';
import { AdminDistribuidorPage } from '../pages/distribuidor_admin/Distribuidor';
import { ReportePartnersPage } from '../pages/reportes/partners';
import { ReportePortabilidadPage } from '../pages/reportes/portabilidad';
import { ContactoPage } from '../pages/ayuda/contacto';
import { CancelacionPage } from '../pages/cancelacion/Cancelacion';
import { SuspensionPage } from '../pages/suspension/Suspension';
import { ReactivacionPage } from '../pages/reactivacion/Reactivacion';
import { CambioLadaPage } from '../pages/cambio_lada/CambioLada';
import { CambioSimPage } from '../pages/cambio_sim/CambioSim';
import { FacturaPage } from '../pages/facturas/Facturas';
import { DashboardConsumos } from '../pages/consumos/DashboardConsumos';

export function PrivateRoutes() {
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  const { roles: userRoles } = user;
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'));

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route
          path="/dashboard"
          component={
            userHasRole(
              [
                'SYSTEM_ADMIN',
                'ADMIN_MEXABLOCK',
                'CALL_CENTER',
                'ADMIN_COMPANY',
                'ADMIN_PARTNER',
                'EMPLOYEE',
                'DISTRIBUTOR',
              ],
              userRoles
            )
              ? DashboardWrapper
              : ErrorsPage
          }
        />
        <Route
          path="/operacion/cuentas"
          component={
            userHasRole(['ADMIN_MEXABLOCK', 'ADMIN_PARTNER'], userRoles)
              ? CuentasPage
              : ErrorsPage
          }
        />
        <Route
          path="/operacion/consumos"
          component={
            userHasRole(
              [
                'SYSTEM_ADMIN',
                'ADMIN_MEXABLOCK',
                'CALL_CENTER',
                'ADMIN_COMPANY',
                'ADMIN_PARTNER',
                'EMPLOYEE',
                'DISTRIBUTOR',
              ],
              userRoles
            )
              ? DashboardConsumos
              : ErrorsPage
          }
        />
        <Route
          path="/operacion/validacion"
          component={
            userHasRole(
              [
                'SYSTEM_ADMIN',
                'ADMIN_MEXABLOCK',
                'CALL_CENTER',
                'ADMIN_COMPANY',
                'ADMIN_PARTNER',
                'EMPLOYEE',
                'DISTRIBUTOR',
              ],
              userRoles
            )
              ? ValidacionPage
              : ErrorsPage
          }
        />
        <Route
          path="/operacion/activaciones"
          component={
            userHasRole(
              [
                'SYSTEM_ADMIN',
                'ADMIN_MEXABLOCK',
                'CALL_CENTER',
                'ADMIN_COMPANY',
                'ADMIN_PARTNER',
                'EMPLOYEE',
                'DISTRIBUTOR',
              ],
              userRoles
            )
              ? ActivacionPage
              : ErrorsPage
          }
        />
        <Route
          path="/operacion/recargas"
          component={
            userHasRole(
              [
                'SYSTEM_ADMIN',
                'ADMIN_MEXABLOCK',
                'CALL_CENTER',
                'ADMIN_COMPANY',
                'ADMIN_PARTNER',
                'EMPLOYEE',
                'DISTRIBUTOR',
              ],
              userRoles
            )
              ? RecargaPage
              : ErrorsPage
          }
        />
        <Route
          path="/operacion/portabilidades"
          component={
            userHasRole(
              [
                'SYSTEM_ADMIN',
                'ADMIN_MEXABLOCK',
                'CALL_CENTER',
                'ADMIN_COMPANY',
                'ADMIN_PARTNER',
                'EMPLOYEE',
                'DISTRIBUTOR',
              ],
              userRoles
            )
              ? PortabilityPage
              : ErrorsPage
          }
        />
        <Route
          path="/operacion/cambios_msisdn"
          component={
            userHasRole(
              [
                'SYSTEM_ADMIN',
                'ADMIN_MEXABLOCK',
                'ADMIN_COMPANY',
                'ADMIN_PARTNER',
                'EMPLOYEE',
                'DISTRIBUTOR',
              ],
              userRoles
            )
              ? CambioMSISDNPage
              : ErrorsPage
          }
        />
        <Route
          path="/reportes/activaciones"
          component={
            userHasRole(
              [
                'SYSTEM_ADMIN',
                'ADMIN_MEXABLOCK',
                'CALL_CENTER',
                'ADMIN_COMPANY',
                'ADMIN_PARTNER',
                'EMPLOYEE',
                'DISTRIBUTOR',
              ],
              userRoles
            )
              ? ReporteActivacionesPage
              : ErrorsPage
          }
        />
        <Route
          path="/reportes/partners"
          component={
            userHasRole(
              [
                'SYSTEM_ADMIN',
                'ADMIN_MEXABLOCK',
                'CALL_CENTER',
                'ADMIN_COMPANY',
                'ADMIN_PARTNER',
                'EMPLOYEE',
                'DISTRIBUTOR',
              ],
              userRoles
            )
              ? ReportePartnersPage
              : ErrorsPage
          }
        />
        <Route
          path="/reportes/portabilidad"
          component={
            userHasRole(
              [
                'SYSTEM_ADMIN',
                'ADMIN_MEXABLOCK',
                'CALL_CENTER',
                'ADMIN_COMPANY',
                'ADMIN_PARTNER',
                'EMPLOYEE',
                'DISTRIBUTOR',
              ],
              userRoles
            )
              ? ReportePortabilidadPage
              : ErrorsPage
          }
        />
        <Route
          path="/reportes/recargas"
          component={
            userHasRole(
              [
                'SYSTEM_ADMIN',
                'ADMIN_MEXABLOCK',
                'CALL_CENTER',
                'ADMIN_COMPANY',
                'ADMIN_PARTNER',
                'EMPLOYEE',
                'DISTRIBUTOR',
              ],
              userRoles
            )
              ? ReporteRecargasPage
              : ErrorsPage
          }
        />
        <Route
          path="/reportes/estado_de_cuenta"
          component={
            userHasRole(
              [
                'SYSTEM_ADMIN',
                'CALL_CENTER',
                'ADMIN_MEXABLOCK',
                'ADMIN_COMPANY',
                'ADMIN_PARTNER',
                'DISTRIBUTOR',
              ],
              userRoles
            )
              ? ReporteEstadoCuentaPage
              : ErrorsPage
          }
        />
        <Route
          path="/reportes/comisiones"
          component={
            userHasRole(
              [
                'SYSTEM_ADMIN',
                'CALL_CENTER',
                'ADMIN_MEXABLOCK',
                'ADMIN_COMPANY',
                'ADMIN_PARTNER',
                'DISTRIBUTOR',
              ],
              userRoles
            )
              ? ReporteComisionesPage
              : ErrorsPage
          }
        />
        <Route
          path="/usuarios"
          component={
            userHasRole(['SYSTEM_ADMIN'], userRoles) ? UsuariosPage : ErrorsPage
          }
        />
        <Route
          path="/facturacion"
          component={
            userHasRole(['ADMIN_MEXABLOCK'], userRoles)
              ? FacturaPage
              : ErrorsPage
          }
        />
        <Route
          path="/empresas"
          component={
            userHasRole(['ADMIN_MEXABLOCK'], userRoles)
              ? EmpresasPage
              : ErrorsPage
          }
        />
        <Route
          path="/call-center/usuarios"
          component={
            userHasRole(['ADMIN_MEXABLOCK', 'CALL_CENTER'], userRoles)
              ? CallCenterPage
              : ErrorsPage
          }
        />
        <Route
          path="/distribuidores"
          component={
            userHasRole(['ADMIN_PARTNER'], userRoles)
              ? DistribuidorPage
              : ErrorsPage
          }
        />
        <Route
          path="/admin_distribuidores"
          component={
            userHasRole(['ADMIN_MEXABLOCK'], userRoles)
              ? AdminDistribuidorPage
              : ErrorsPage
          }
        />
        <Route
          path="/clientes"
          component={
            userHasRole(
              [
                'SYSTEM_ADMIN',
                'ADMIN_MEXABLOCK',
                'CALL_CENTER',
                'ADMIN_COMPANY',
                'ADMIN_PARTNER',
                'EMPLOYEE',
                'DISTRIBUTOR',
              ],
              userRoles
            )
              ? ClientesPage
              : ErrorsPage
          }
        />
        <Route
          path="/empleados"
          component={
            userHasRole(
              ['ADMIN_COMPANY', 'ADMIN_PARTNER', 'EMPLOYEE', 'DISTRIBUTOR'],
              userRoles
            )
              ? EmpleadosPage
              : ErrorsPage
          }
        />
        <Route
          path="/notificaciones"
          component={
            userHasRole(['ADMIN_MEXABLOCK'], userRoles)
              ? NotificationsPage
              : ErrorsPage
          }
        />
        <Route
          path="/ayuda/configuracion_equipos"
          component={
            userHasRole(
              [
                'SYSTEM_ADMIN',
                'ADMIN_MEXABLOCK',
                'CALL_CENTER',
                'ADMIN_COMPANY',
                'ADMIN_PARTNER',
                'EMPLOYEE',
                'DISTRIBUTOR',
              ],
              userRoles
            )
              ? ConfigEquiposPage
              : ErrorsPage
          }
        />

        <Route
          path="/ayuda/contacto"
          component={
            userHasRole(
              [
                'SYSTEM_ADMIN',
                'ADMIN_MEXABLOCK',
                'CALL_CENTER',
                'ADMIN_COMPANY',
                'ADMIN_PARTNER',
                'EMPLOYEE',
                'DISTRIBUTOR',
              ],
              userRoles
            )
              ? ContactoPage
              : ErrorsPage
          }
        />

        <Route
          path="/empresa/socio/:id"
          component={
            userHasRole(
              [
                'SYSTEM_ADMIN',
                'ADMIN_MEXABLOCK',
                'CALL_CENTER',
                'ADMIN_COMPANY',
                'ADMIN_PARTNER',
                'EMPLOYEE',
                'DISTRIBUTOR',
              ],
              userRoles
            )
              ? SocioPage
              : ErrorsPage
          }
        />
        <Route
          path="/call-center/suspension"
          component={
            userHasRole(['ADMIN_MEXABLOCK', 'CALL_CENTER'], userRoles)
              ? SuspensionPage
              : ErrorsPage
          }
        />
        <Route
          path="/call-center/cancelacion"
          component={
            userHasRole(['ADMIN_MEXABLOCK', 'CALL_CENTER'], userRoles)
              ? CancelacionPage
              : ErrorsPage
          }
        />
        <Route
          path="/call-center/reactivacion"
          component={
            userHasRole(['ADMIN_MEXABLOCK', 'CALL_CENTER'], userRoles)
              ? ReactivacionPage
              : ErrorsPage
          }
        />
        <Route
          path="/operacion/cambio-lada"
          component={
            userHasRole(['ADMIN_MEXABLOCK', 'CALL_CENTER'], userRoles)
              ? CambioLadaPage
              : ErrorsPage
          }
        />
        <Route
          path="/call-center/cambio-sim"
          component={
            userHasRole(['ADMIN_MEXABLOCK', 'CALL_CENTER'], userRoles)
              ? CambioSimPage
              : ErrorsPage
          }
        />

        <Route path="/perfil" component={ProfilePage} />
        <Redirect from="/auth" to="/dashboard" />
        <Redirect exact from="/" to="/dashboard" />
        <Redirect to="/error/404" />
      </Switch>
    </Suspense>
  );
}
