import axios from 'axios';
const API_URL = process.env.REACT_APP_API;

export async function getConsumption(body: any) {
  const { msisdn, iccid } = body;
  const data = {
    msisdn: msisdn,
    iccid: iccid,
  };
  const response = await axios.post(`${API_URL}consumption/`, data);

  return response;
}
