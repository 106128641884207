import React, { useState, useEffect, useMemo, useRef } from 'react';
import { KTSVG, SimpleAlert, userHasRole } from '../../../../../_mexablock/helpers';
import { Modal } from 'react-bootstrap-v5';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { NumericFormat } from 'react-number-format';
import { shallowEqual, useSelector } from 'react-redux';
import { approvisionamiento, verifyBalance } from '../../../../service/operacion/Cuentas';
import Select from 'react-select';
import { getSelectEmpresas } from '../../../../service/empresas/Empresas';
import { ApprovisionValues } from '../models/CuentaModel';
import { useHistory } from 'react-router-dom';
import { UserModel } from '../../../../modules/auth/models/UserModel';
import { RootState } from '../../../../../setup';
import { getAllSelect } from '../../../../service/distribuidores/Distribuidores';
import { styleSelect } from '../../../../../_mexablock/helpers/StyleSelect';
import { StepperComponent } from '../../../../../_mexablock/assets/ts/components';
import { getSelectCatalog } from '../../../../service/catalog/Catalogs';
import { getAllCommissions } from '../../../../service/commission/commission';

const validationSchemas = [
  Yup.object({
    virtual: Yup.number()
      .min(1)
      .required(),
    empresa: Yup.mixed()
      .required(),
  }),
  Yup.object({
    tipoPago: Yup.mixed().required('Selecciona tipo de pago'),
    file: Yup.mixed().required('File is required'),
  }),
]

type Props = {
  show: boolean;
  handleClose: (updateGrid: boolean) => void;
};


const AprovisionamientoModal: React.FC<Props> = ({
  show,
  handleClose,
}: any) => {
  const userSesion: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  const { roles: userRoles } = userSesion;
  const [loading, setLoading] = useState(false);
  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  const [radioSelection, setRadioSelection] = useState('');
  const [empresas, setEmpresas] = useState<any[]>([]);
  const [tiposPago, setTiposPago] = useState<any[]>();
  const [commissions, setCommisssions] = useState<any[]>([]);
  const [commission, setCommission] = useState(0);
  const [typeCommission, setTypeCommission] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [textStepper, setTextStepper] = useState('Continuar');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [currentSchema, setCurrentSchema] = useState(validationSchemas[0]);
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(
      stepperRef.current as HTMLDivElement
    );
  };
  const history = useHistory();
  const empresasOptions = useMemo(() => empresas
    .filter((emp) => emp.type.code === radioSelection), [empresas, radioSelection]);
  const initialValues: ApprovisionValues = {
    empresa: null,
    efectivo: 0,
    virtual: 0,
    comments: '',
    file: null,
    tipoPago: null,
  };
  const formBalance = useFormik({
    validationSchema: currentSchema,
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values, { setStatus, setSubmitting, setFieldError }) => {
      if (!stepper.current) {
        return;
      }
      switch (stepper.current.currentStepIndex) {
        case 1:
          setLoading(true);
          const saldoVirtualAmount = values.virtual;
          const verifyRes = await verifyBalance(saldoVirtualAmount);
          if (verifyRes.data.doc.data) {
            stepper.current.goNext();
            setCurrentSchema(validationSchemas[1])
            setTextStepper('Aprovisionar');
          } else {
            SimpleAlert(verifyRes.data.message, 3000, 'error');
          }
          setLoading(false);
          break;
        case 2:
          if (!showConfirmationModal) {
            setShowConfirmationModal(true);
          } else {
            setLoading(true);
            setShowConfirmationModal(false);
            const body = {
              [isAdmin ? 'companyId' : 'distributorId']: values.empresa.id,
              saldoVirtualAmount: values.virtual,
              payType: values.tipoPago.id,
              pdfFile: values.file,
              comments: values.comments,
            }
            const res = await approvisionamiento(body);
            setLoading(false);
          }
          break;
      }
    },
  });
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formBalance.setFieldValue('empresa', null);
    setRadioSelection(event.target.value);
  };
  const handleDistribuidorChange = (value: any) => {
    if (value) {
      setTypeCommission(value.account.typeCommission.code);
      setCommission(Number(commissions.find((item) => item.type_account_id === value.account.type_id)?.percentage ?? 0) / 100);
      formBalance.setFieldValue('virtual', 0);
      formBalance.setFieldValue('efectivo', 0);
    }
    formBalance.setFieldValue('empresa', value);
  };
  const handleEmpresaChange = (value: any) => {
    if (value) {
      setTypeCommission(value.account.typeCommission.code);
      setCommission(Number(commissions.find((item) => item.type_account_id === value.account.type_id)?.percentage ?? 0) / 100);
      formBalance.setFieldValue('virtual', 0);
      formBalance.setFieldValue('efectivo', 0);
    }
    formBalance.setFieldValue('empresa', value);
  };
  const handleCloseModal = () => {
    setTimeout(() => {
      formBalance.resetForm();
      stepper.current?.goto(1);
      setTypeCommission('');
      setRadioSelection('');
      setCurrentSchema(validationSchemas[0])
      setLoading(false);
      setTextStepper("Continuar");
    }, 300);
    handleClose(false);
  };
  const handleConfirmation = () => {
    formBalance.handleSubmit();
  };
  const onEffectiveChange = (values: any) => {
    const { floatValue } = values;
    formBalance.setFieldValue(
      'efectivo',
      floatValue ?? 0,
    );
    formBalance.setFieldValue(
      'virtual',
      floatValue ? floatValue * (1 + commission) : 0,
    );
  };
  const onVirtualChange = (values: any) => {
    const { floatValue } = values;
    formBalance.setFieldValue(
      'virtual',
      floatValue ?? 0,
    );
    formBalance.setFieldValue(
      'efectivo',
      floatValue ? floatValue * (1 - commission) : 0,
    );
  };
  useEffect(() => {
    const fetchPost = async () => {
      try {
        const tdp = await getSelectCatalog("TDP");
        const comms = await getAllCommissions({
          type_operation: "COS",
        });
        if (userHasRole(['ADMIN_MEXABLOCK'], userRoles)) {
          setIsAdmin(true);
          const empRes = await getSelectEmpresas();
          setEmpresas(empRes);
        } else {
          const dist = await getAllSelect({});
          setEmpresas(dist);
        }
        if (tdp) setTiposPago(tdp);
        if (comms) setCommisssions(comms);
      } catch (err) {
        console.error(err);
        history.push('/error/500');
      }
    };
    fetchPost();
  }, []);
  useEffect(() => {
    if (show) {
      loadStepper();
    }
  }, [stepperRef, show]);
  return (
    <>
      <Modal
        id="kt_modal_create"
        tabIndex={-1}
        aria-hidden="true"
        dialogClassName="modal-dialog modal-dialog-centered modal-lg"
        show={show}
        onHide={handleCloseModal}
      >
        <div className="modal-content">
          <div className="modal-header pb-0 border-0 justify-content-end">
            <div
              className="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
              onClick={handleCloseModal}
            >
              <KTSVG
                className="svg-icon-2"
                path="/media/icons/duotune/arrows/arr061.svg"
              />
            </div>
          </div>
          <div className="modal-body scroll-y mx-5 pt-0">
            <div className="text-center mb-5">
              <h1 className="mb-3">Aprovisionar Saldo</h1>
            </div>
            <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
              <div
                ref={stepperRef}
                className="stepper stepper-links d-flex flex-column gap-5 first"
                id="kt_modal_create_app_stepper"
              >
                <div className="stepper-nav justify-content-center py-2">
                  <div
                    className="stepper-item current"
                    data-kt-stepper-element="nav"
                  >
                    <h3 className="stepper-title">Validacion de Saldo</h3>
                  </div>
                  <div
                    className="stepper-item pending"
                    data-kt-stepper-element="nav"
                  >
                    <h3 className="stepper-title">Proceso de Pago</h3>
                  </div>
                </div>
                <form
                  className="form"
                  action="#"
                  onSubmit={formBalance.handleSubmit}
                >
                  <div
                    className="current min-h-500px"
                    data-kt-stepper-element="content"
                  >
                    <div>
                      {isAdmin ? (
                        <>
                          <div className="row g-9 mb-13 justify-content-center">
                            <div className="col-md-4 d-flex align-items-center justify-content-center">
                              <div className="w-100 d-flex flex-column align-items-center">
                                <label className="required fw-bold fs-6 mb-2 w-auto">
                                  Empresa
                                </label>
                                <input
                                  placeholder="EMP"
                                  className="form-check-input mb-3 mb-lg-0"
                                  type="radio"
                                  name="selection"
                                  value="EMP"
                                  checked={radioSelection === 'EMP'}
                                  onChange={handleRadioChange}
                                />
                              </div>
                            </div>
                            <div className="col-md-4 d-flex align-items-center justify-content-center">
                              <div className="w-100 d-flex flex-column align-items-center">
                                <label className="required fw-bold fs-6 mb-2 w-auto">
                                  Socio
                                </label>
                                <input
                                  placeholder="SOC"
                                  className="form-check-input mb-3 mb-lg-0"
                                  type="radio"
                                  name="selection"
                                  value="SOC"
                                  checked={radioSelection === 'SOC'}
                                  onChange={handleRadioChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row justify-content-center mb-4" style={{ minHeight: 20 }}>
                            {formBalance.touched.empresa &&
                              formBalance.errors.empresa && (
                                <div className="fv-plugins-message-container text-danger w-auto ms-5">
                                  <span role="alert">
                                    Es necesario seleccionar una empresa o socio
                                  </span>
                                </div>
                              )}
                          </div>
                          <div className="row justify-content-center mb-15">
                            <div className="col-md-3 fv-row fv-plugins-icon-container d-flex flex-column align-items-center">
                              <div className="w-100 text-center">
                                <label className="required fw-bold fs-6 mb-2 w-auto">
                                  Empresa o socio
                                </label>
                              </div>
                              <Select
                                options={empresasOptions}
                                styles={styleSelect}
                                menuPosition={'fixed'}
                                placeholder={'Seleccione'}
                                isSearchable={true}
                                isDisabled={!radioSelection}
                                noOptionsMessage={() => (
                                  <span>No se encontraron registros</span>
                                )}
                                onChange={handleEmpresaChange}
                                value={formBalance.values.empresa}
                                backspaceRemovesValue
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="row justify-content-center mb-4" style={{ minHeight: 20 }}>
                            {formBalance.touched.empresa &&
                              formBalance.errors.empresa && (
                                <div className="fv-plugins-message-container text-danger w-auto ms-5">
                                  <span role="alert">
                                    Es necesario seleccionar un distribuidor
                                  </span>
                                </div>
                              )}
                          </div>
                          <div className="row justify-content-center mb-15">
                            <div className="col-md-3 fv-row fv-plugins-icon-container d-flex flex-column align-items-center">
                              <div className="w-100 text-center">
                                <label className="required fw-bold fs-6 mb-2 w-auto">
                                  Distribuidor
                                </label>
                              </div>
                              <Select
                                options={empresas}
                                styles={styleSelect}
                                menuPosition={'fixed'}
                                placeholder={'Seleccione'}
                                isSearchable={true}
                                noOptionsMessage={() => (
                                  <span>No se encontraron registros</span>
                                )}
                                onChange={handleDistribuidorChange}
                                value={formBalance.values.empresa}
                                backspaceRemovesValue
                              />
                            </div>
                          </div>
                        </>
                      )}
                      <div className="row justify-content-center mb-1" style={{ minHeight: '20px' }}>
                        {formBalance.touched.virtual &&
                          formBalance.errors.virtual && (
                            <div className="fv-plugins-message-container text-danger w-auto ms-5">
                              <span role="alert">
                                Saldo Requerido
                              </span>
                            </div>
                          )}
                      </div>
                      <div className="row">
                        <div className="fv-row col mb-7 fv-plugins-icon-container">
                          <div className="w-100 d-flex">
                            <label className="required disabled fs-6 fw-semibold form-label mb-2">
                              Efectivo
                            </label>
                          </div>
                          <NumericFormat
                            name="efectivo"
                            className="form-control mb-3"
                            value={formBalance.values.efectivo}
                            decimalScale={2}
                            decimalSeparator="."
                            fixedDecimalScale
                            placeholder="$ 0.00"
                            prefix="$ "
                            maxLength={16}
                            thousandSeparator=","
                            disabled={typeCommission !== "SVV"}
                            onValueChange={onEffectiveChange}
                          />
                        </div>
                        <div className="fv-row col mb-7 fv-plugins-icon-container">
                          <div className="w-100 d-flex">
                            <label className="required disabled fs-6 fw-semibold form-label mb-2">
                              Saldo Virtual
                            </label>
                          </div>
                          <NumericFormat
                            name="virtual"
                            className="form-control mb-3"
                            value={formBalance.values.virtual}
                            decimalScale={2}
                            decimalSeparator="."
                            fixedDecimalScale
                            placeholder="$ 0.00"
                            prefix="$ "
                            maxLength={16}
                            thousandSeparator=","
                            disabled={typeCommission !== "EFF"}
                            onValueChange={onVirtualChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    data-kt-stepper-element="content"
                    className="pending min-h-500px"
                  >
                    <div className="row justify-content-center">
                      <div className="row justify-content-center mb-1" style={{ minHeight: '20px' }}>
                        {formBalance.touched.tipoPago &&
                          formBalance.errors.tipoPago && (
                            <div className="fv-plugins-message-container text-danger w-auto ms-5">
                              <span role="alert">
                                El tipo de pago es requerido
                              </span>
                            </div>
                          )}
                      </div>
                      <div className="row justify-content-center mb-15">
                        <div className="col-md-3 fv-row fv-plugins-icon-container d-flex flex-column align-items-center">
                          <div className="w-100 text-center">
                            <label className="required fw-bold fs-6 mb-2 w-auto">
                              Tipo de Pago
                            </label>
                          </div>
                          <Select
                            options={tiposPago}
                            styles={styleSelect}
                            menuPosition={'fixed'}
                            placeholder={'Seleccione'}
                            isSearchable={true}
                            noOptionsMessage={() => (
                              <span>No se encontraron registros</span>
                            )}
                            onChange={(val) => formBalance.setFieldValue('tipoPago', val)}
                            value={formBalance.values.tipoPago}
                            backspaceRemovesValue
                          />
                        </div>
                      </div>
                      <div className="fv-row" style={{ textAlign: 'center' }}>
                        <h3 className="required">Agregar Comprobante</h3>
                        {formBalance.touched.file &&
                          formBalance.errors.file && (
                            <div className="fv-plugins-message-container text-danger w-auto ms-5">
                              <span role="alert">
                                El comprobante de pago es requerido
                              </span>
                            </div>
                          )}
                        <br></br>
                        <input
                          className="form-control required"
                          type="file"
                          onChange={(e) => formBalance.setFieldValue('file', e.target.files ? e.target.files[0] : null)}
                          id="formFile"
                        />
                      </div>
                      <div className="fv-row">
                        <label className="fs-6 fw-semibold form-label mb-2">
                          Agregue una nota
                        </label>

                        <textarea
                          className="form-control rounded-3 mb-5"
                          {...formBalance.getFieldProps('comments')}
                          rows={4}
                          style={{ resize: 'none' }}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="fs-7 text-muted mb-15">
                    Tenga en cuenta que todos los cambios de saldo manuales serán
                    auditados por el equipo financiero. Por favor conserve sus
                    facturas y recibos hasta entonces. Gracias.
                  </div>
                  <div className="text-center">
                    <button
                      type="reset"
                      className="btn btn-light me-3"
                      data-kt-users-modal-action="cancel"
                      onClick={handleCloseModal}
                    >
                      Cancelar
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      data-kt-users-modal-action="submit"
                      disabled={loading}
                    >
                      {!loading && (
                        <span className="indicator-label">{textStepper}</span>
                      )}
                      {loading && (
                        <span
                          className="indicator-progress"
                          style={{ display: 'block' }}
                        >
                          Espere por favor...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        show={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
        centered
      >
        <Modal.Header>
          <Modal.Title>Confirmación</Modal.Title>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => setShowConfirmationModal(false)}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </Modal.Header>
        <Modal.Body>¿Está seguro que desea aplicar los cambios?</Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-light"
            onClick={() => setShowConfirmationModal(false)}
          >
            Cancelar
          </button>
          <button className="btn btn-primary" onClick={handleConfirmation}>
            Confirmar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export { AprovisionamientoModal };
