import React, { useEffect, useRef, useState } from 'react';
import ApexCharts, { ApexOptions } from 'apexcharts';
import { getCSSVariableValue } from '../../../assets/ts/_utils';
import {
  calculatePercentage,
  formatNumber,
  calculateRemainingPercentage,
} from '../../../helpers';

type Props = {
  className: string;
  chartColor: string;
  chartHeight: string;
  title: string;
  low?: any;
  total: number;
  consumo: number;
  diponible: number;
  saldo: string;
  adquiridoAlta: number;
  adquiridoBaja?: number;
  consumidoAlta: number;
  consumidoBaja?: number;
  disponibleAlta: number;
  disponibleBaja?: number;
};

const RadialGraphic: React.FC<Props> = ({
  className,
  chartColor,
  chartHeight,
  title,
  low,
  total,
  consumo,
  diponible,
  saldo,
  adquiridoAlta,
  adquiridoBaja,
  consumidoAlta,
  consumidoBaja,
  disponibleAlta,
  disponibleBaja,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  // const [newConsumo, setNewConsumo] = useState<any>();
  // const [newTotal, setnewTotal] = useState<any>();
  // useEffect(() => {
  //   if (consumo) setNewConsumo(consumo);
  //   if (total) setnewTotal(total);
  // }, [consumo, total]);

  const refreshChart = () => {
    if (!chartRef.current) {
      return;
    }

    const chart = new ApexCharts(
      chartRef.current,
      chartOptions(total, consumo)
    );
    if (chart) {
      chart.render();
    }

    return chart;
  };

  useEffect(() => {
    const chart = refreshChart();

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [total, consumo]);

  return (
    <div className={`card card-custom card-flush ${className} bg-opacity-0`}>
      <div className="card-header justify-content-center align-items-center">
        <div className="card-title d-flex flex-column">
          <div className="d-flex align-items-center">
            <span className="fs-2 fw-bolder text-gray-900 me-2 lh-1 ls-n2 ">
              {title}
            </span>
          </div>
        </div>
      </div>

      <div className="card-body pt-0">
        <div className="row align-items-center">
          <div className="col-md-12 d-flex flex-column justify-content-center align-items-center position-relative">
            <div
              id="kt_card_widget_17_chart"
              ref={chartRef}
              style={{
                minWidth: '100px',
                minHeight: '100px',
                width: '170px',
                height: '150px',
              }}
            ></div>

            <div
              style={{
                position: 'absolute',
                bottom: '55px',
                left: '50%',
                transform: 'translateX(-50%) translateY(0)',
                textAlign: 'center',
              }}
            >
              <div
                style={{
                  fontSize: '12px',
                  color: '#3498db',
                  fontWeight: 'bold',
                }}
              >
                {formatNumber(diponible)}
              </div>
              <div
                style={{
                  fontSize: '12px',
                  color: '#3498db',
                  fontWeight: 'bold',
                }}
              >
                {saldo}
              </div>
            </div>
          </div>
          {low ? (
            <div className="row p-0 m-0">
              {/* Primera columna */}
              <div className="col-md-6 text-start">
                <label className="fw-bolder text-primary">Vel. Alta</label>

                <div className="mb-1">
                  <label className="fw-bolder">Adquirido</label>
                  <div
                    className="fw-bold text-muted text-truncate"
                    title="32323423432422423"
                  >
                    {formatNumber(adquiridoAlta)} {saldo}
                  </div>
                </div>
                <div className="mb-1">
                  <label className="fw-bolder">Consumido</label>
                  <div className="fw-bold text-muted">
                    {formatNumber(consumidoAlta)} {saldo}
                  </div>
                </div>
                <div className="mb-1">
                  <label className="fw-bolder">Disponible</label>
                  <div className="fw-bold text-muted">
                    {formatNumber(disponibleAlta)} {saldo}
                  </div>
                </div>
              </div>
              {/* Segunda columna */}
              <div className="col-md-6 text-end">
                <label className="fw-bolder text-primary">Vel. Reducida</label>
                <div className="mb-1">
                  <label className="fw-bolder">Adquirido</label>
                  <div className="fw-bold text-muted">
                    {formatNumber(adquiridoBaja ?? 0)} {saldo}
                  </div>
                </div>
                <div className="mb-1">
                  <label className="fw-bolder">Consumido</label>
                  <div className="fw-bold text-muted">
                    {formatNumber(consumidoBaja ?? 0)} {saldo}
                  </div>
                </div>
                <div className="mb-1">
                  <label className="fw-bolder">Disponible</label>
                  <div className="fw-bold text-muted">
                    {formatNumber(disponibleBaja ?? 0)} {saldo}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="row p-0 m-0">
              <div className="col-md-12 text-center">
                <div className="mb-1">
                  <label className="fw-bolder">Adquirido</label>
                  <div
                    className="fw-bold text-muted text-truncate"
                    title="32323423432422423"
                  >
                    {formatNumber(total)} {saldo}
                  </div>
                </div>
                <div className="mb-1">
                  <label className="fw-bolder">Consumido</label>
                  <div className="fw-bold text-muted">
                    {formatNumber(consumo)} {saldo}
                  </div>
                </div>
                <div className="mb-1">
                  <label className="fw-bolder">Disponible</label>
                  <div className="fw-bold text-muted">
                    {formatNumber(diponible)} {saldo}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const chartOptions = (
  dataTotal: number,
  dataAbailable: number
): ApexOptions => {
  const baseColor = getCSSVariableValue('--bs-primary');
  const lightColor = '#D6FFEB';
  const labelColor = '#000';

  return {
    series: [calculateRemainingPercentage(dataTotal, dataAbailable)],
    chart: {
      // fontFamily: 'inherit',
      height: '200px',
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: '60%',
        },
        dataLabels: {
          name: {
            show: true,
            fontSize: '18px',
            fontWeight: 'bolder',
            color: labelColor,
            offsetY: -10,
          },
          value: {
            show: false,
          },
        },
        track: {
          background: lightColor,
          strokeWidth: '100%',
        },
      },
    },
    colors: [baseColor],
    stroke: {
      lineCap: 'round',
    },
    labels: ['Total'],
  };
};

export { RadialGraphic };
